import React from 'react';
import '../sentry.server.config';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';

import Header from '../src/components/Header';
import Layout from '../src/components/Layout';
import Content from '../src/containers/Pages/Dashboard/Content';

import HelpscoutComponent from '../src/components/HelpscoutComponent';

const Index = () => {
  return (
    <SessionAuth>
      <Layout title="Dashboard | GMB Fitness™">
        <HelpscoutComponent />
        <Header />
        <Content />
      </Layout>
    </SessionAuth>
  );
};

export default Index;
