import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAdvertisementProducts } from 'reducers/advertisement';
import Text from 'components/core/Text';
import imageUrlGenerator from 'utils/imageUrlGenerator';
import { isServer } from 'utils/getEnvironment';
import Section from 'components/core/Section';
import Container from 'components/Pages/Dashboard/Container';
import { requestAdvertisementProduct } from 'actions/advertisement';
import { Image, Price, Description, AddProduct, Products } from './styles';

interface Product {
  id: string;
  name: string;
  price: number;
  picture: string;
  link: string;
}

interface AddProductsProps {}

const getSlicedProgramName = (name: string) => {
  if (name) {
    if (name.length > 21) {
      return `${name.slice(0, 21)}...`;
    }
    return name;
  }
  return '';
};

const AddProducts: FC<AddProductsProps> = () => {
  const dispatch = useDispatch();
  const advertisementProducts = useSelector(getAdvertisementProducts);

  useEffect(() => {
    if (!advertisementProducts?.length && !isServer()) {
      dispatch(requestAdvertisementProduct());
    }
  }, [advertisementProducts?.length, dispatch]);

  if (!advertisementProducts?.length) return null;

  return (
    <Section fillViewport={false} mb={4} py={6}>
      <Container>
        <Text variant="sectionHeader" transform="uppercase" mb={4}>
          Add Programs
        </Text>
        <Products>
          {advertisementProducts.map((product: Product) => (
            <AddProduct
              key={product.id}
              href={`${product.link ? product.link : `/products/buy?productId=${product.id}`}`}
            >
              <Image src={imageUrlGenerator(product.picture, '290x163', '357x185')} />
              <Price>${product.price}</Price>
              <Description>
                <Text variant="mainHeader" isCondensed color="white" tag="h4" mb={1}>
                  Add {getSlicedProgramName(product.name)}
                </Text>
              </Description>
            </AddProduct>
          ))}
        </Products>
      </Container>
    </Section>
  );
};

export default AddProducts;
