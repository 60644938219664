import { FC } from 'react';
import moment from 'moment';

import Text from 'components/core/Text';

import ExternalLink from 'components/ExternalLink';
import { Wrapper, NewsAvatar, NewsDescription } from './styles';

interface BlogPostProps {
  variant?: 'large' | 'small';
  link?: string;
  picture?: string;
  title?: string;
  date?: string;
  content?: string;
}

const BlogPost: FC<BlogPostProps> = ({
  link,
  picture,
  date,
  title,
  content,
  variant = 'large',
}) => {
  return (
    <Wrapper variant={variant} target="_blank" href={link ?? ''}>
      {picture && (
        <NewsAvatar
          style={{
            backgroundImage: `url(${picture})`,
          }}
        />
      )}
      <NewsDescription>
        {!!date && (
          <Text variant="captionSmall" tag="h3" color="grayLight" mb={1}>
            {moment(date).format('MMM DD')}
          </Text>
        )}
        <Text variant="textSmall" tag="h3" weight="bold" color="black" transform="uppercase" mb={2}>
          {title}
        </Text>
        <Text variant={variant === 'large' ? 'text' : 'textSmall'} tag="p" marginBottom={4}>
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: content ?? '',
            }}
          />
        </Text>
        <ExternalLink>Read more</ExternalLink>
      </NewsDescription>
    </Wrapper>
  );
};

export default BlogPost;
