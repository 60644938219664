import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 0 auto;
  width: 930px;

  @media screen and (max-width: 992px) {
    margin: 0 auto;
    padding-top: 1px;
    width: 89.333%;
  }
`;

export default Wrapper;
