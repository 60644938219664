import React from 'react';
import Text from 'components/core/Text';
import { StyledBar, CloseButton } from './styles';

interface AnnouncementBarProps {
  message: string;
  linkText?: string;
  linkUrl?: string;
  onClose: () => void;
}

const AnnouncementBar: React.FC<AnnouncementBarProps> = ({ message, onClose }) => {
  return (
    <StyledBar>
      <Text variant="text" align="justify" pt={2}>
        <span dangerouslySetInnerHTML={{ __html: message }} />
      </Text>
      <CloseButton onClick={onClose} />
    </StyledBar>
  );
};

export default AnnouncementBar;
