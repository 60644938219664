import * as React from 'react';
import Text from 'components/core/Text';
import ProgramSchedule from 'components/ProgramSchedule';
import Bullet from 'components/core/Bullet';
import { SessionDetails, SessionName, Bullets } from './styles';
import Link from '../Link';

export default function SessionInfo(props) {
  const {
    program,
    program: { nextSession },
  } = props;

  return (
    <SessionDetails>
      <div style={{ minWidth: 1 }}>
        <SessionName>
          <Link
            href={`/programs/${program.id}/sessions/${nextSession?.id}`}
            style={{ textDecoration: 'none' }}
          >
            <Text
              variant="sectionHeader"
              weight="medium"
              color="black"
              tag="h1"
              transform="uppercase"
              withEllipsis
            >
              {nextSession?.name}
            </Text>
          </Link>
        </SessionName>
        <Text pt={4} pb={5}>
          {nextSession?.description}
        </Text>
        {nextSession?.bullets && (
          <Bullets>
            {nextSession?.bullets.map((bullet, id) => (
              <Bullet key={String(id)}>
                <Text tag="span">{bullet}</Text>
              </Bullet>
            ))}
          </Bullets>
        )}
      </div>
      <ProgramSchedule program={program} showProgram />
    </SessionDetails>
  );
}
