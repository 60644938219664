import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// presentational component
import { getPrograms } from 'reducers/programs';
import Content from 'components/Pages/Dashboard/Content';

// selectors
import {
  getLastUserWorkoutSessions,
  getLastUserWorkoutSessionDate,
} from '../../../reducers/workout';
import { getIsResumingSession } from '../../../reducers/exercises';
import { showModal } from '../../../actions/modal';
import { clearExercisesState } from '../../../actions/exercise';
import { getProductDataRequest } from '../../../actions/products';

const mapStateToProps = state => {
  return {
    userWorkOutSessions: getLastUserWorkoutSessions(state.workout),
    programs: getPrograms(state),
    activities: state.activities.all,
    lastUserWorkoutSessionDate: getLastUserWorkoutSessionDate(state.workout),
    isResumingSession: getIsResumingSession(state.exercises),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showModal,
      clearExercisesState,
      getProductDataRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Content);
