import Text from 'components/core/Text';
import { useDispatch, useSelector } from 'react-redux';
import { getBlogPosts } from 'reducers/community';
import { isServer } from 'utils/getEnvironment';
import { useEffect, useMemo } from 'react';
import Section from 'components/core/Section';
import { requestFetchBlogPosts } from 'actions/community';
import Container from 'components/Pages/Dashboard/Container';
import BlogPost from 'components/BlogPost';

const BlogSection = () => {
  const dispatch = useDispatch();
  const blogPosts = useSelector(getBlogPosts);
  const blogPost = blogPosts.length ? blogPosts[0] : null;
  const blogPostHighlightedText = useMemo(() => (blogPost ? blogPost?.excerpt?.rendered : null), [
    blogPost,
  ]);

  useEffect(() => {
    if (!blogPosts.length && !isServer()) {
      dispatch(
        requestFetchBlogPosts({
          numberOfPosts: 1,
        }),
      );
    }
  }, [blogPosts.length, dispatch]);

  if (!blogPosts.length) return null;

  return (
    <Section fillViewport={false} mb={4} py={6} pb={8}>
      <Container>
        <Text variant="sectionHeader" transform="uppercase" mb={6}>
          NEWS AND HIGHLIGHTS
        </Text>
        <BlogPost
          variant="large"
          date={blogPost?.date}
          link={blogPost?.link}
          picture={blogPost?.featuredPicture}
          title={blogPost?.title.rendered}
          content={blogPostHighlightedText}
        />
      </Container>
    </Section>
  );
};

export default BlogSection;
