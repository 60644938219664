import InternalLink from 'components/InternalLink';
import colors from 'constants/colors';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  flex: 0 0 auto;

  @media screen and (max-width: 992px) {
    margin: 24px 0;
    width: 100%;
  }
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Week = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const Day = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: ${colors.main.grey};
  border-radius: 4px;
  margin-top: 8px;
  height: 25px;
  width: 55px;

  &:not(:last-of-type) {
    margin-right: 10px;
  }
`;

const ProgramLink = styled(InternalLink)`
  font-size: 16px;
  padding-bottom: 16px;
  max-width: 256px;
`;

export { TitleRow, TitleBlock, Week, Day, ProgramLink, Wrapper };
