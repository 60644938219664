import styled from 'styled-components';
import InternalLink from '../InternalLink';
import colors from '../../constants/colors';
import pointRight from '../../assets/icons/point_right.svg';

const SessionDetails = styled.div`
  display: grid;
  padding-top: 24px;
  gap: 12px;
  width: 100%;
  grid-template-columns: auto 265px;

  @media screen and (max-width: 992px) {
    display: block;
  }
`;

const SessionName = styled.div`
  width: 99%;
`;

const Bullets = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 24px;
`;

const Bullet = styled.li`
  display: inline-flex;
  align-items: center;
  width: 100%;

  &:before {
    background: url(${pointRight}) 50% 50% no-repeat;
    background-size: contain;
    content: '';
    flex-shrink: 0;
    margin-right: 12px;
    height: 26px;
    width: 26px;
  }
`;

const StyledInternalLink = styled(InternalLink)`
  font-size: 16px;
  padding-bottom: 16px;
  max-width: 256px;
`;

// block schedule
const Schedule = styled.div`
  display: inline-block;
  position: relative;
  margin-left: 32px;
  width: 260px;
  flex: 0 0 auto;

  @media screen and (max-width: 992px) {
    margin: 24px 0;
    width: 100%;
  }
`;

const ScheduleTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ScheduleTitleBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ScheduleDays = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const ScheduleDay = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: ${colors.main.fandango};
  border-radius: 4px;
  margin-top: 8px;
  height: 25px;
  width: 55px;

  &:not(:last-of-type) {
    margin-right: 10px;
  }
`;

export {
  SessionDetails,
  SessionName,
  Bullet,
  Bullets,
  Schedule,
  StyledInternalLink,
  ScheduleTitleRow,
  ScheduleTitleBlock,
  ScheduleDay,
  ScheduleDays,
};
