import { FC, useMemo } from 'react';

import Text from 'components/core/Text';
import { scheduleOrder } from 'constants/schedule';
import { ProgramScheduleType, Program } from 'reducers/programs/types';
import ButtonAddToCalendar from 'components/ButtonAddToCalendar';

import { Wrapper, TitleRow, TitleBlock, Day, Week, ProgramLink } from './styles';

interface Props {
  /**
   * Program to show the schedule for
   * @default null
   * */
  program?: Program;

  /**
   * Whether to show the program link or not
   * @default false
   */
  showProgram?: boolean;

  /**
   * Whether to show the program link or not
   * @default true
   */
  showChange?: boolean;
}

const ProgramSchedule: FC<Props> = ({ program, showProgram, showChange }) => {
  const { schedule } = program || {};
  const filteredSchedule = useMemo(
    () =>
      schedule
        ? (scheduleOrder as (keyof ProgramScheduleType)[]).filter(key => !!schedule[key])
        : null,
    [schedule],
  );

  if (!filteredSchedule || !program) {
    return null;
  }

  return (
    <Wrapper>
      {showProgram && <ProgramLink href={`/programs/${program.id}`}>{program.name}</ProgramLink>}
      <TitleRow>
        <TitleBlock>
          <div>
            <Text variant="sectionHeader" transform="uppercase" tag="h3" mb={1}>
              Schedule
            </Text>
            <Text variant="captionSmall" color="grayLight">
              {filteredSchedule.length > 0
                ? `${filteredSchedule.length} TIMES PER WEEK`
                : 'NO SCHEDULE HAS BEEN SET'}
            </Text>
          </div>
          {filteredSchedule.length > 0 && (
            <ButtonAddToCalendar program={program} schedule={filteredSchedule} />
          )}
        </TitleBlock>
        {showChange && <ProgramLink href="/schedule">Change</ProgramLink>}
      </TitleRow>
      <Week>
        {filteredSchedule.map((key, id) => (
          <Day key={String(id)}>
            <Text variant="caption" weight="medium" color="white" transform="uppercase">
              {key}
            </Text>
          </Day>
        ))}
      </Week>
    </Wrapper>
  );
};

export default ProgramSchedule;
