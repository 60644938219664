import Link from 'components/Link';
import colors from 'constants/colors';
import styled from 'styled-components';

interface WrapperProps {
  variant?: 'large' | 'small';
}

const NewsAvatar = styled.div`
  background-color: ${colors.main.dividers};
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
  margin-right: 30px;
  height: 253px;
  width: 450px;

  @media screen and (max-width: 992px) {
    height: 189px;
    margin: 0;
    margin-bottom: 16px;
    width: 100%;
  }

  @media screen and (max-width: 992px) and (orientation: landscape) {
    height: 350px;
  }

  @media screen and (min-width: 765px) and (max-width: 992px) and (orientation: portrait) {
    height: 350px;
  }
`;

const NewsDescription = styled.div`
  width: calc(100% - 480px);

  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;

const Wrapper = styled(Link)<WrapperProps>`
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  ${({ variant }) =>
    variant === 'small' &&
    `
        flex-direction: column;
    `}

  ${NewsDescription} {
    ${({ variant }) =>
      variant === 'small' &&
      `
            width: 100%;
        `}
  }

  ${NewsAvatar} {
    ${({ variant }) =>
      variant === 'small' &&
      `
            width: 100%;
            height: 147px;
            margin: 0 0 16px;
        `}
  }
`;

export { Wrapper, NewsAvatar, NewsDescription };
