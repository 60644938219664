import styled from 'styled-components';
import colors from 'constants/colors';
import Link from 'components/Link';

const Products = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  row-gap: 16px;

  @media screen and (max-width: 992px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`;

const AddProduct = styled(Link)`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  transition: all 0.5s;
  line-height: 18px;
  position: relative;
  height: 163px;
  width: 290px;

  @media screen and (max-width: 992px) {
    width: 100%;

    &:nth-of-type(2) {
      border-top: 1px solid ${colors.main.dividers};
    }

    &:nth-of-type(odd) {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 992px) and (orientation: landscape) {
    height: 350px;
  }

  @media screen and (min-width: 765px) and (max-width: 992px) and (orientation: portrait) {
    height: 350px;
  }
`;

const Price = styled.span`
  color: ${colors.main.white};
  position: absolute;
  top: 10%;
  right: 5%;
`;

const Image = styled.img`
  display: block;
  height: 100%;
  width: 100%;
  filter: brightness(0.8);
  object-fit: cover;
`;

const Description = styled.div`
  bottom: 13px;
  left: 20px;
  right: 20px;
  position: absolute;
  z-index: 100;
`;

export { AddProduct, Products, Price, Image, Description };
