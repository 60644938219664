import styled from 'styled-components';
// @ts-ignore
import pointRight from '../../../assets/icons/point_right.svg';

const Bullet = styled.li`
  display: inline-flex;
  align-items: center;
  width: 100%;

  &:before {
    background: url(${pointRight}) 50% 50% no-repeat;
    background-size: contain;
    content: '';
    flex-shrink: 0;
    margin-right: 12px;
    height: 26px;
    width: 26px;
  }
`;

export default Bullet;
