import * as React from 'react';

import Wrapper from './styles';

export default class extends React.PureComponent {
  render() {
    const { children } = this.props;
    return <Wrapper>{children}</Wrapper>;
  }
}
