import styled from 'styled-components';

const ScheduledActivitiesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  align-items: flex-start;

  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(3, 80%);
  }
`;

export default ScheduledActivitiesWrapper;
